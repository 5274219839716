<ap-header [type]="'transparent'" [compact]="false" (onClickLogo)="navigateToMainPage()">
  <ng-template ngTemplate="title">
    <div class="flex-normal">
      <div>Bookkeeping Connect</div>
      <ng-container *ngIf="showTenantName">
        <div class="ap-header-subtitle-divider"></div>
        <div class="app-text-heading text-wrap-ellipsis " ap-tooltip [direction]="'top'" [tooltipId]="'tenantName'"
          [tooltipContent]="tenantByRoute?.nme | decodeString" ariaDescribedby="'Tenant Name'">{{tenantByRoute?.nme |
          decodeString}}</div>
      </ng-container>
    </div>
  </ng-template>
  <ng-container *ngIf="authUser$ | async as user">
    <ng-template ngTemplate="options">
      <div ap-tooltip [tooltipId]="'appNotifications'" ariaDescribedby="App Notifications"
        [tooltipContent]="'Notifications'" [direction]="'bottom'">
        <ap-header-options-item [iconName]="'notification-outline'" (onClick)="toggleNotificationPanel()"
          [styleClass]="notificationsService.unreadCount > 0 ? 'required-dot-right' : ''">
        </ap-header-options-item>
      </div>
    </ng-template>
    <ng-template ngTemplate="user">
      <div class="flex-normal">
        <app-cus-avatar class="navbar-avatar" [styleClass]="'bookkeeper-avatar-list'" [imageSrc]="user?.avatar"
          [ariaLabel]="'User Profile ' + user.name" [name]="user.abbr" [withDropdown]="true" borderWidth="0"
          diameter="40" [role]="'button'" [list]="navList" [attr.data-pendo-id]="avatarPendoId">
          <ng-template ngTemplate="dropdownTemp">
            <div class="ap-option-item flex-gap-5 height-83 no-hover">
              <app-cus-avatar [name]="user.abbr" [imageSrc]="user?.avatar" [withDropdown]="false" [disabled]="true"
                borderWidth="0" diameter="83" [role]="'button'" tabindex="-1"></app-cus-avatar>
              <div class="user-info">
                <div class="text-wrap-ellipsis ap-font-weight-2" title="{{ user.name }}">{{ user.name }}</div>
                <div class="text-wrap-ellipsis ap-typography-body-s" title="{{user?.email}}">{{ user?.email }}</div>
              </div>
            </div>
            <ng-container *ngFor="let item of navList; let i = index;">
              <ng-container [ngSwitch]="item.category">
                <ng-container *ngSwitchCase="'divider'">
                  <hr class="ap-mx-spacing-2 full-divider">
                </ng-container>
                <ng-container *ngSwitchCase="'info'">
                  <div class="app-text-light ap-px-spacing-3 ap-my-spacing-5">
                    <div class="ap-typography-body-xs">Primary Customer</div>
                    <span class="ap-typography-body-s navbar-topbar-user-tenant text-ellipsis"
                      title="{{user.tenant ? user.tenant.nme : 'No Tenant'}}">
                      {{ user.tenant ? user.tenant.nme : 'No Tenant' }}
                    </span>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'link'">
                  <ap-dropdown-list-item #dropdownListItem [item]="item" (onSelectItem)="onSelectItem($event)"
                    *ngxPermissionsOnly="item.permission || null" [role]="'list item'" [attr.aria-label]="item.label"
                    [attr.data-pendo-id]="item.value === 'User_Management' ? userManagementPendoId : null">
                    <ng-template *ngIf="item.iconName" ngTemplate="prefixTemp">
                      <span class="Appkit4-icon icon-{{item.iconName}}"></span>
                    </ng-template>
                    <ng-template *ngIf="item.descValue" ngTemplate="suffixTemp">
                      <span>{{item.descValue}}</span>
                    </ng-template>
                  </ap-dropdown-list-item>
                </ng-container>
                <ng-container *ngSwitchCase="'change_theme'">
                  <ap-dropdown-list-item [item]="item" (onSelectItem)="themeChange()" [role]="'list item'"
                    [attr.aria-label]="item.label">
                    <ng-template *ngIf="item.descValue" ngTemplate="suffixTemp">
                      <span>{{item.descValue}}</span>
                    </ng-template>
                  </ap-dropdown-list-item>
                </ng-container>
              </ng-container>
            </ng-container>
          </ng-template>
        </app-cus-avatar>
      </div>
    </ng-template>
  </ng-container>
</ap-header>
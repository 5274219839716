import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NavigationModule } from '@appkit4/angular-components/navigation';
import { EmitService, LayoutService } from '@core';
import { LayoutConfig } from '@models';
import { cloneDeep } from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { combineLatestWith, filter } from 'rxjs/operators';

@Component({
  selector: 'app-cus-sidenav',
  templateUrl: './cus-sidenav.component.html',
  styleUrls: ['./cus-sidenav.component.scss'],
  standalone: true,
  imports: [CommonModule, NavigationModule],
})
export class CusSidenavComponent {
  solid: boolean = false;
  simple: boolean = true;
  public collapsed: boolean = false;
  selectedIndex: number = -1;
  selectedSubIndex: number = -1;
  layoutConfig: LayoutConfig;
  navList = [];

  constructor(
    private readonly router: Router,
    public readonly layoutService: LayoutService,
    public readonly emitService: EmitService,
    private readonly permissionsService: NgxPermissionsService,
  ) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        combineLatestWith(this.emitService.switchTenant$),
      )
      .subscribe(([event, tenant]) => {
        this.layoutConfig = layoutService.layoutConfig;
        this.navList = [];
        this.navList.push(
          {
            name: 'Overview',
            routerLink: this.covertRouterLink(
              'overview',
              this.layoutConfig.tenantKey,
            ),
            prefixIcon: 'community',
            permission: 'VIEW_BOOKKEEPING',
          },
          {
            name: 'Dashboard',
            routerLink: this.covertRouterLink(
              'domo-dashboard',
              this.layoutConfig.tenantKey,
            ),
            prefixIcon: 'dashboard',
            permission: 'VIEW_DASHBOARD',
          },
          {
            name: 'Tasks',
            routerLink: this.covertRouterLink(
              'task-list/customers-task-list',
              this.layoutConfig.tenantKey,
            ),
            prefixIcon: 'check-hook',
            permission: 'VIEW_TASK_LIST',
          },
          {
            name: 'Files',
            routerLink: this.covertRouterLink(
              'reports',
              this.layoutConfig.tenantKey,
            ),
            prefixIcon: 'file',
            permission: 'VIEW_REPORT',
          },
          {
            name: 'Quick Links',
            routerLink: this.covertRouterLink(
              'quick-links',
              this.layoutConfig.tenantKey,
            ),
            prefixIcon: 'link',
            permission: 'VIEW_QUICK_LINK',
          },
        );
        this.sideItemInit();
        this.matchNavItem();
      });
  }

  sideItemInit() {
    this.navList = this.mapNestedArray(cloneDeep(this.navList));
  }

  mapNestedArray(list: any[]) {
    return list
      .map((item) => {
        if (
          item.permission &&
          !this.permissionsService.getPermission(item.permission)
        ) {
          return null;
        } else {
          if (item.children && Array.isArray(item.children)) {
            item.children = this.mapNestedArray(item.children);
          }
        }
        return item;
      })
      .filter((item) => this.filterNestedArray(item));
  }

  filterNestedArray(item) {
    if (!item) {
      return false;
    } else {
      if (
        item.children &&
        Array.isArray(item.children) &&
        item.children.length !== 0
      ) {
        item.children = item.children.filter((_item) =>
          this.filterNestedArray(_item),
        );
      }
      return true;
    }
  }

  matchNavItem() {
    this.selectedIndex = -1;
    this.selectedSubIndex = -1;
    const urlParts = window.location.pathname.split('/');
    this.navList.forEach((item, index) => {
      if (item.children) {
        item.children?.forEach((subItem, subIndex) => {
          const linkParts = subItem.routerLink.split('/');
          if (linkParts.every((part, i) => part === urlParts[i])) {
            this.selectedIndex = index;
            this.selectedSubIndex = subIndex;
          }
        });
      } else {
        const linkParts = item.routerLink.split('/');
        if (linkParts.every((part, i) => part === urlParts[i])) {
          this.selectedIndex = index;
        }
      }
    });
  }

  redirect(event: any) {
    // trigger a global click to close all kinds (notification/comment/note) panel
    document.querySelector('body').dispatchEvent(
      new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );
    if (event?.subItem) {
      this.router.navigateByUrl(event.subItem.routerLink);
    }
    if (event.item?.routerLink) {
      this.router.navigateByUrl(event.item.routerLink);
    }
  }

  onCollapsedSidebar({ status }): void {
    this.collapsed = status;
    this.layoutService.fullScreen$.next(this.collapsed);
  }

  private covertRouterLink(path: string, tenantKey?: number) {
    return tenantKey ? `/customer/${tenantKey}/${path}` : `/customer/${path}`;
  }
}

import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
} from '@angular/router';
import { ComboboxModule } from '@appkit4/angular-components/combobox';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { HeaderModule } from '@appkit4/angular-components/header';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import { AuthService, EmitService, LayoutService, PendoService } from '@core';
import { FeatureManagementService } from '@core/services/feature-management.service';
import { NotificationsService } from '@core/services/notifications.service';
import { StyleControlService } from '@core/services/style-control.service';
import { CustomersService } from '@core/services/unmatched-transactions.service';
import { getTenantLevel } from '@core/services/util.service';
import { LayoutConfig, Tenant, User } from '@models';
import {
  AvatarComponent,
  CustomizeTemplate,
} from '@shared/component/appkit4-avatar/avatar.component';
import { SpinnerComponent } from '@shared/component/spinner/spinner.component';
import { AvatarHandleDirective } from '@shared/directive/avatar-handle/avatar-handle.directive';
import { DecodeStringPipe } from '@shared/pipe/decode-string/decode-string.pipe';
import { plainToClass } from 'class-transformer';
import { unescape } from 'lodash';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
@Component({
  selector: 'app-cus-navbar',
  templateUrl: './cus-navbar.component.html',
  styleUrls: ['./cus-navbar.component.scss'],
  standalone: true,
  imports: [
    SpinnerComponent,
    NgxPermissionsModule,
    RouterLink,
    FormsModule,
    AsyncPipe,
    DecodeStringPipe,
    AvatarHandleDirective,
    AvatarComponent,
    CommonModule,
    HeaderModule,
    TooltipModule,
    CustomizeTemplate,
    ComboboxModule,
    DropdownModule,
    ToggleModule,
  ],
})
export class CusNavbarComponent implements OnInit, OnDestroy {
  layoutConfig: LayoutConfig;
  authUser$: Observable<User> = this.authService.user$;

  tenants = [];
  filterTenants = [];

  tenantSelected: Tenant | any;
  switchSubs: Subscription = null;

  @ViewChild('cusNavAvatar') appAvatar;

  get pendoId(): string {
    return this.pendoService.generalPendoId('toolbar', 'ham-menu-icon');
  }

  get avatarPendoId(): string {
    return this.pendoService.generalPendoId('toolbar', 'user-profile-icon');
  }

  get accountPendoId(): string {
    return this.pendoService.generalPendoId(
      'toolbar',
      'user-profile-menu',
      'account-connections',
    );
  }

  get userManagementPendoId(): string {
    return this.pendoService.generalPendoId(
      'toolbar',
      'user-profile-menu',
      'user-management',
    );
  }

  navList: any = [
    {
      value: 'Info',
      category: 'info',
    },
    {
      category: 'divider',
    },
    {
      value: 'My_Profile',
      link: '/customer/profile',
      label: 'My Profile',
      category: 'link',
    },
    {
      value: 'User_Management',
      link: '/customer/member',
      label: 'User Management',
      permission: 'USER_MGMT_VIEW_USER',
      category: 'link',
    },
    {
      value: 'Activity_Log',
      link: '/customer/activity-log',
      label: 'Activity Log',
      permission: 'CUSTOMER_VIEW_ACTIVITY_LOG',
      category: 'link',
    },
    {
      category: 'tour',
    },
    {
      category: 'divider',
    },
    {
      value: 'change_theme',
      label: 'Light/Dark theme',
      descValue: 'T',
      category: 'change_theme',
    },
    {
      value: 'Logout',
      link: 'logout',
      label: 'Log Out',
      permission: '',
      category: 'link',
    },
  ];

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private readonly pendoService: PendoService,
    public readonly emitService: EmitService,
    public readonly notificationsService: NotificationsService,
    private customersService: CustomersService,
    private readonly featureManagementService: FeatureManagementService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly styleControlService: StyleControlService,
    private readonly route: ActivatedRoute,
  ) {
    this.layoutConfig = layoutService.layoutConfig;
  }

  ngOnInit() {
    this.checkNavItem(this.router.url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const currentUrl = (event as NavigationEnd).url;
        this.checkNavItem(currentUrl);
      });
    this.authService.user$.pipe(map((user) => plainToClass(User, user)));

    let tenantKey =
      this.layoutConfig.tenantKey ||
      this.layoutService.userInfo.tenant.tenantKey;
    this.customersService
      .listHierarchicalAssignedTenants()
      .subscribe((assignedTenants) => {
        this.filterTenants = [];
        assignedTenants?.data &&
          assignedTenants?.data?.forEach((tenant) =>
            this.flattenTenants(tenant),
          );
        this.tenants = this.filterTenants;
        this.tenantSelected = this.tenants.find((item) => {
          return item.tenantKey === tenantKey;
        });
        if (!this.tenantSelected) {
          this.tenantSelected = this.tenants[0];
          tenantKey = this.tenantSelected.tenantKey;
        }
        this.emitService.switchTenant$.next({ type: 'init', tenantKey });
      });
  }

  ngOnDestroy(): void {
    this.emitService.switchTenant$.next({ type: '', tenantKey: 0 });
  }

  flattenTenants(tenant: any) {
    tenant.nme && (tenant.nme = unescape(tenant.nme));
    this.filterTenants.push({
      ...tenant,
      label: tenant.nme,
      value: tenant.tenantKey,
      level: getTenantLevel(tenant.lineage),
    });
    if (tenant.subentities?.length > 0) {
      tenant.subentities.forEach((subTenant) => {
        this.flattenTenants(subTenant);
      });
    }
  }

  switchTenant(optionSelected: any) {
    this.emitService.startLoading();
    if (this.switchSubs) {
      this.switchSubs.unsubscribe();
    }
    this.tenantSelected = optionSelected;
    const tenantKey = this.tenantSelected.tenantKey;
    const { queryParams, fragment } = this.route.snapshot;
    const currentUrl = this.router.url
      .replace(/(\d+)/, tenantKey)
      .split(/[?#]/)[0];
    this.switchSubs = combineLatest([
      this.authService.dispatchNewUser(tenantKey),
      this.featureManagementService.getTenantDisPermission(tenantKey),
      this.featureManagementService.listPermissions4CustomerAdmin(),
    ]).subscribe(([user, { disablePermissions }, customerAdminPermission]) => {
      const rolePermission = this.layoutConfig.isBKViewCustomer
        ? customerAdminPermission.data
        : user?.permissions;
      const currentPermission = rolePermission
        .map((p) => p.nme)
        .filter((p) => !disablePermissions.includes(p));
      this.permissionsService.loadPermissions(currentPermission);

      // refresh the page to make the permissions get effect
      this.router
        .navigateByUrl(
          this.layoutConfig.isBKViewCustomer
            ? `/customer/${tenantKey}/overview`
            : '/customer/overview',
          { skipLocationChange: true },
        )
        .then(() =>
          this.router.navigate([currentUrl], { queryParams, fragment }),
        )
        .then(() => this.emitService.finalEndLoading());

      this.emitService.switchTenant$.next({
        type: 'tenantSwitched',
        tenantKey,
      });
      this.emitService.showNotificationsPanel$.next(false);
      this.emitService.endLoading();
    });
  }

  onFilterResult(event) {
    if (event.searchStr) {
      this.filterTenants = JSON.parse(
        JSON.stringify(
          this.tenants.filter((tenant) =>
            tenant.nme?.toLowerCase().includes(event.searchStr.toLowerCase()),
          ),
        ),
      );
    } else {
      this.filterTenants = JSON.parse(JSON.stringify(this.tenants));
    }
  }

  async logout() {
    await this.authService.logout();
  }

  checkNavItem(url: string) {
    this.navList.forEach((n) => (n.checked = false));
    const navItem = this.navList.find((n) => url.includes(n.link));
    if (navItem) {
      navItem.checked = true;
    }
  }

  onSelectItem({ selected }: any) {
    if (selected.value === 'Logout') {
      this.logout();
    } else {
      this.router.navigate([selected.link]);
    }
  }

  restartTour() {
    this.appAvatar.showDropdown = false;
  }

  toggleNotificationPanel() {
    this.emitService.showNotificationsPanel$.next(
      !this.emitService.showNotificationsPanel$.getValue(),
    );
  }

  navigateToMainPage() {
    this.router.navigate(['/']);
  }

  themeChange(): void {
    if (this.styleControlService.dataMode === 'dark') {
      this.styleControlService.dataMode = 'light';
      this.styleControlService.updateTheme('LIGHT').subscribe();
    } else {
      this.styleControlService.dataMode = 'dark';
      this.styleControlService.updateTheme('DARK').subscribe();
    }
  }
}

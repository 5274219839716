import { Injectable } from '@angular/core';
import { NotificationDialogType, NotificationService } from '@core';
import { WarningModalDialogComponent } from '@shared/component/warning-modal-dialog/warning-modal-dialog.component';
import {
  GeneralModalComponent,
  GENERAL_MODAL_ID,
} from '@shared/component/general-modal/general-modal.component';
import { DynamicDialogService } from '@appkit4/angular-components/modal';
import { of, Subject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { base64StringToBlob } from 'blob-util';
import { orderBy, isEqual, sumBy, values, pick, reduce } from 'lodash';
import { User } from 'src/app/models';
import { UntypedFormGroup } from '@angular/forms';
import { NumberToDayWithNAPipe } from '@shared/pipe/customers-related/number-to-day-with-na';
import parser from 'cron-parser';
import moment from 'moment';
import businessDaysHelper from './business-days-service';
@Injectable({
  providedIn: 'root',
})
export class UtilService {
  public singleDropSub$: Subject<any> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly notificationService: NotificationService,
    private readonly dynamicDialogService: DynamicDialogService,
  ) {}

  unsavedChangeAlert(title, content = undefined) {
    return this.openGeneralModal(title, content);
  }

  unsavedChangeExtraAlert(title, extraLabel) {
    const modal = this.dynamicDialogService.openModal(
      GeneralModalComponent,
      { appModalId: GENERAL_MODAL_ID, maskCloseable: false },
      { title, extraLabel, ariaLabel: title },
    );
    return modal.instance.afterClosed();
  }

  openGeneralModal(
    title,
    content,
    labelStr = 'Continue',
    primaryBtnType = 'primary',
  ) {
    const data = { title, labelStr, primaryBtnType };
    if (content) {
      Object.assign(data, { content });
    }
    const modal = this.dynamicDialogService.openModal(
      GeneralModalComponent,
      { appModalId: GENERAL_MODAL_ID, maskCloseable: false, ariaLabel: title },
      data,
    );
    return modal.instance.afterClosed().pipe(
      switchMap((result) => {
        return of(result && typeof result === 'string' ? true : false);
      }),
    );
  }

  openModal(targetModal, appModalId, data = {}) {
    const modal = this.dynamicDialogService.openModal(
      targetModal,
      { appModalId, maskCloseable: false },
      data,
    );
    return modal.instance.afterClosed();
  }

  formatAmount(value: string | number, withDollarSign = true) {
    if (!value) {
      return '';
    }
    value = value + '';
    const splitValue = value.split('.');
    let splitValueOne = splitValue[0];
    const splitValueTwo = splitValue.length > 1 ? '.' + splitValue[1] : '';
    const rgx = /(\d+)(\d{3})/;
    while (rgx.test(splitValueOne)) {
      splitValueOne = splitValueOne.replace(rgx, '$1' + ',' + '$2');
    }
    return withDollarSign
      ? `$${splitValueOne}${splitValueTwo}`
      : `${splitValueOne}${splitValueTwo}`;
  }

  calculateArrowPositionOfTooltips(
    targetClassName: string,
    iconClassName: string,
    offsetVar: string,
  ) {
    const circlePlusEl: DOMRect = document
      .getElementsByClassName(iconClassName)[0]
      .getBoundingClientRect();
    const L1 = circlePlusEl?.left;
    const R1 = circlePlusEl?.right;
    setTimeout(() => {
      const manualTooltipEl: DOMRect = document
        .getElementsByClassName(targetClassName)[0]
        ?.getBoundingClientRect();
      const L2 = manualTooltipEl?.left;
      const R2 = manualTooltipEl?.right;
      const existed = L1 && L2 && R1 && R2;
      existed &&
        document.documentElement.style.setProperty(
          offsetVar,
          ((R2 - (L1 + R1) / 2) / (R2 - L2)) * 100 - 3 + '%',
        );
    }, 100);
  }

  reloadPage(url: string, extras?: NavigationExtras) {
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigate([url], extras));
  }

  navigate(url: string, extras?: NavigationExtras) {
    this.router.navigate([url], extras);
  }

  downloadAttachmentFile(fileBase64Content: any, fileName: string) {
    if (!fileBase64Content) {
      return;
    }
    const blob = base64StringToBlob(
      fileBase64Content,
      'application/octet-stream',
    );
    const fileUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = decodeURIComponent(fileName);
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      }),
    );

    setTimeout(() => {
      window.URL.revokeObjectURL(link.href);
      link.remove();
    }, 100);
  }

  async convert2LocalDownload(fileEnctype: any) {
    const fileToBase64 = async (blob: Blob) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    };
    const file = await fileToBase64(fileEnctype);
    this.downloadAttachmentFile(
      // strip type("*;base64,") from Javascript FileReader base64 string
      String.prototype.split.call(file, ',')[1],
      fileEnctype.name,
    );
  }

  compareValueChange(value1: any, value2: any): boolean {
    if (!value1 || !value2) {
      return false;
    }
    const value1Copy = JSON.parse(JSON.stringify(value1));
    const value2Copy = JSON.parse(JSON.stringify(value2));
    return isEqual(value1Copy, value2Copy);
  }

  usersDefaultOrder(users: User[], orders: any[]) {
    return orderBy(
      users,
      [
        (o) => {
          let avatar = '';
          if (o.firstNme !== null) {
            avatar += o.firstNme.charAt(0).toUpperCase();
          }
          if (o.lastNme !== null) {
            avatar += o.lastNme.charAt(0).toUpperCase();
          }
          return avatar;
        },
      ],
      orders,
    );
  }

  filterAndSortUsers(
    users: User[],
    roles: String[] = ['SUPER_ADMIN', 'BOOKKEEPER_ADMIN', 'BOOKKEEPER'],
    orders: any[] = ['asc'],
  ) {
    return this.usersDefaultOrder(
      users?.filter((item) => roles.includes(item?.userType?.nme)),
      orders,
    );
  }

  convertEndDate(endDate) {
    if (endDate) {
      return new Date(
        new Date(endDate).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1,
      ).toISOString();
    } else {
      return undefined;
    }
  }

  cutOffImage(imgSrc: string, domContainer: any, sideLength: number): void {
    const maskContainer = document.createElement('div');
    maskContainer.style.width = sideLength + 'px';
    maskContainer.style.height = sideLength + 'px';
    maskContainer.style.backgroundPosition = 'center';
    maskContainer.style.backgroundSize = 'cover';
    maskContainer.style.backgroundImage = `url(${imgSrc})`;
    maskContainer.style.borderRadius = '50%';
    domContainer.replaceWith(maskContainer);
  }

  setYearEndClose(formGroup: UntypedFormGroup) {
    const financialYear: string = formGroup.get('financialYear').value;
    if (!financialYear) return;
    formGroup.controls['monthClose'].enable();
    formGroup.controls['dayClose'].enable();
    if (financialYear === 'Fiscal Year') {
      formGroup.get('monthClose').setValue(6);
      formGroup.get('dayClose').setValue(30);
    } else {
      formGroup.get('monthClose').setValue(12);
      formGroup.get('dayClose').setValue(31);
    }
  }

  setDayCloseDropDown(formGroup: UntypedFormGroup) {
    formGroup.get('dayClose').setValue('');
    const month: number =
      formGroup.get('monthClose').value?.value ??
      formGroup.get('monthClose').value;
    return new NumberToDayWithNAPipe().getDayArray(month);
  }

  getOuterPanelLayoutInfo() {
    // fixed max width of the table
    const tableWidthMaxVal = 1232;
    const screenWidth = window.innerWidth;
    // left menu panel area
    const sidenavWidth =
      document
        .getElementsByClassName('mat-sidenav menu-container')[0]
        ?.getClientRects()[0]?.width || 0;
    // the space beside table
    const spaceWidth = (screenWidth - tableWidthMaxVal - sidenavWidth) / 2;
    // right comment/note/report panel area, which is fixed to 630px
    const panelWidth = 630;
    const dynamicTableWidth =
      screenWidth - sidenavWidth - spaceWidth - panelWidth - 80;
    return {
      collapseTable: spaceWidth < panelWidth,
      dynamicTableWidth,
    };
  }

  generateDateSequence(dateRange: number, formatName: DateFormatNme) {
    if (!Number.isInteger(dateRange)) {
      throw new Error('dateRange must integer');
    }
    if (dateRange < 0) {
      throw new Error('dateRange must more than zero');
    }
    const dateRangeList = [...Array(dateRange + 1).keys()];
    dateRangeList.shift();
    return dateFormat(dateRangeList, formatName);
  }

  sumTaskCountHandler(data, keysInData: string[]) {
    return sumBy(
      values(
        pick(data, [
          'preonboardingChart',
          'onboardingChart',
          'bookkeepingChart',
          'miscellaneousChart',
        ]),
      ),
      (o) => reduce(keysInData, (sum, n) => sum + o[n], 0),
    );
  }

  /**
   *
   * @param container element conatiner selector. If activatedRow is null, then scroll to the top of the elements.
   * @param activatedRow target selector. If activatedRow value was passed, then will scroll to near the target element
   */

  scroll2Target(
    container = 'customer-mat-container',
    activatedRow = 'row-activated',
  ) {
    const activeRowElement =
      activatedRow && document.getElementsByClassName(activatedRow)[0];
    const tableTop = document.getElementsByClassName(container)[0];
    if (activeRowElement) {
      activeRowElement.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else if (tableTop) {
      tableTop.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  showWaitMessage(userCount: number) {
    // When invite 1-5 users, the toast reminder shows "1 minutes."
    // When invite 6-10 users, the toast reminder shows "2 minutes."
    this.addToastMessage(
      `The users are added to Bookkeeping Connect. Please refresh the page in ${
        userCount > 5 ? 2 : 1
      } minutes.`,
      null,
      null,
      'wait',
    );
  }

  addToastMessage(
    message: string,
    duration = 5000,
    themeColor = 'green',
    statusIcon = 'success',
  ) {
    this.notificationService.notifications$.next({
      type: statusIcon,
      message: message,
    });
  }

  addBannerMessage(
    message: string,
    bannerStatus: BannerStatus = BannerStatus.error,
  ) {
    this.notificationService.notifications$.next({
      type: bannerStatus,
      message: message,
    });
  }

  setFilterList(dynamicFilterCounts: any, list: any[]) {
    return list.map((item) => ({
      ...item,
      label: item.name,
      descValue:
        (dynamicFilterCounts ? dynamicFilterCounts[item.countKey] : 0) + '',
    }));
  }
}

export enum BannerStatus {
  warning = 'warning',
  error = 'error',
  info = 'information',
  success = 'success',
}

export const compareDate = (
  dueDate: string | Date | any,
  dueDays: number = -1,
): boolean => {
  const currentTime = new Date();
  const currentDay = new Date(
    new Date(currentTime).getFullYear(),
    new Date(currentTime).getMonth(),
    new Date(currentTime).getDate(),
  ).getTime();
  const dueDay = new Date(
    new Date(dueDate).getFullYear(),
    new Date(dueDate).getMonth(),
    new Date(dueDate).getDate(),
  ).getTime();
  if (dueDays > 0) {
    return (
      dueDay > currentDay && dueDay < currentDay + dueDays * 1000 * 60 * 60 * 24
    );
  } else if (dueDays === 0) {
    return dueDay === currentDay;
  } else {
    return dueDay < currentDay;
  }
};

export const dateFormat = (dateRange: number[], formatName: DateFormatNme) => {
  if (formatName === DateFormatNme.MONTH) {
    return dateRange
      .filter((date) => date !== 0)
      .map((date) => {
        return date + monthNth(date);
      });
  }
};

export enum DateFormatNme {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  YEAR = 'YEAR',
}

export const monthNth = (d: number): string => {
  const j = d % 10;
  if (j == 1 && d != 11) {
    return 'st';
  }
  if (j == 2 && d != 12) {
    return 'nd';
  }
  if (j == 3 && d != 13) {
    return 'rd';
  }
  return 'th';
};

export const dateMarker = () => {
  return new Date(
    new Date().getFullYear(),
    new Date().getMonth(),
    new Date().getDate(),
    0,
    0,
    0,
  );
};

export const generateRandomValue = () => {
  const crypto = window.crypto;
  var array = new Uint32Array(1);
  crypto.getRandomValues(array); // Compliant for security-sensitive use cases
  return array[0];
};

export const getTenantLevel = (lineage: string) => {
  return (lineage ? lineage.split(',').length : 0) + 1;
};

/**
 * the function is used to generate next due date of a recurring task
 * @param repeat frequency of a task (DAILY|WEEKLY|EVERY_2_WEEKS|MONTHLY|EVERY_OTHER_MONTH|QUARTERLY)
 * @param sendOn the specific day of frequency (EVERY_DAY｜LAST_DAY｜MONDAY-FRIDAY｜1-31）- used to generate task
 * @param dueOn the specific day of frequency（EVERY_DAY｜LAST_DAY｜MONDAY-FRIDAY｜1-31）- used to generate task due date
 * @param currentDate current timestamp in client machine
 * @param cronJobUpdateDate update timestamp of a cron task
 * @returns the next due date of a recurring task
 */
export const nextDueOn = (
  repeat: string,
  sendOn: string,
  dueOn: string,
  currentDate: Date,
  cronJobUpdateDate: Date,
): Date => {
  const fromDate = cronJobUpdateDate || currentDate;
  const sendOnDate = nextIntervalDate(repeat, sendOn, fromDate);
  let dueOnDate = nextIntervalDate(repeat, dueOn, fromDate);
  // if due date is earlier than generated date, then push due date to next iteration
  if (dueOnDate < sendOnDate) {
    dueOnDate = nextIntervalDate(repeat, dueOn, sendOnDate);
  }
  return dueOnDate;
};

/**
 * the function is used to generate next iteration date based on cron job expression
 * @param repeat frequency of iteration (DAILY|WEEKLY|EVERY_2_WEEKS|MONTHLY|EVERY_OTHER_MONTH|QUARTERLY)
 * @param cron the specific day of frequency（EVERY_DAY｜LAST_DAY｜MONDAY-FRIDAY｜1-31
 * @param fromDate Start date of the iteration
 * @returns the next iteration date
 */
export const nextIntervalDate = (
  repeat: string,
  cron: string,
  fromDate: Date,
): Date => {
  let cronExp = genCronExpStr(repeat, cron);
  let nextDueDate = new Date();
  const options = {
    currentDate: fromDate,
  };
  let interval = parser.parseExpression(cronExp, options);
  const fields = JSON.parse(JSON.stringify(interval.fields));
  // adapt the dayOfMonth when input a invalid number for cron expression
  if (fields.dayOfMonth.length === 1) {
    const nthOfMonth = fields.dayOfMonth[0];
    const monthList = fields.month; // month from 1 Jan, 2 Feb, 3 Mar, ...
    const currentMonth = fromDate.getMonth(); // 0 Jan, 1 Feb, 2 Mar, 3 Apr, ... IT IS DIFFERENT FROM fields.month
    // handle months without 31st day, and Feb without 29th, 30th, 31st
    if (
      nthOfMonth === 31 ||
      (monthList.includes(2) && currentMonth === 1 && nthOfMonth > 28)
    ) {
      cronExp = cronExp.replace(nthOfMonth, 'L');
    }
  }

  // BiWeekly - It needs calculate dynamically
  if (cronExp.includes('*/14')) {
    /**
     * There is no specific day in the cron expression, so it can not calculate by cron-parser
     * It should use the cron task created timestamp as the current date to calculate
     *
     * If there is only one task progress, then it is the initial one, check based on updated_at column in task cron table
     * If there are multiple tasks apart from initial one, check based on create timestamp of task progress
     */
    // convert biweekly to weekly and run the parser twice to get the next job timestamp
    cronExp = cronExp.replace('*/14', '*');
    let biWeeklyFlag = true;
    // if the cron job is later than the current date, use the same week to create the cron job
    if (moment(fromDate).isoWeekday() <= fields.dayOfWeek[0]) {
      // use weekly cron job, the work day is the same week with the created timestamp
      biWeeklyFlag = false;
    }

    const nextInterval = parser.parseExpression(cronExp, options);
    if (biWeeklyFlag) {
      // use next() twice to calculate biweekly
      nextInterval.next();
      nextDueDate = new Date(nextInterval.next().toString());
    } else {
      nextDueDate = new Date(nextInterval.next().toString());
    }
  } else {
    // It is determined by the specific day in cron expression
    interval = parser.parseExpression(cronExp, options);
    nextDueDate = new Date(interval.next().toString());
  }
  return businessDaysHelper.currentOrNextBusinessDay(nextDueDate);
};

/**
 * the function is used to generate cron expression
 * @param frequency frequency of iteration (DAILY|WEEKLY|EVERY_2_WEEKS|MONTHLY|EVERY_OTHER_MONTH|QUARTERLY)
 * @param cron the specific day of frequency（EVERY_DAY｜LAST_DAY｜MONDAY-FRIDAY｜1-31
 * @returns cron expression
 */
export function genCronExpStr(frequency: string, cron: string): string {
  /** cron expression format
    *    *    *    *    *    *
    ┬    ┬    ┬    ┬    ┬    ┬
    │    │    │    │    │    |
    │    │    │    │    │    └ day of week (0 - 7, 1L - 7L) (0 or 7 is Sun)
    │    │    │    │    └───── month (1 - 12)
    │    │    │    └────────── day of month (1 - 31, L)
    │    │    └─────────────── hour (0 - 23)
    │    └──────────────────── minute (0 - 59)
    └───────────────────────── second (0 - 59, optional)
   */

  const cronExp = ['59', '59', '23', '*', '*', '*'];
  switch (frequency) {
    case 'DAILY':
      break;
    case 'WEEKLY':
      break;
    case 'EVERY_2_WEEKS':
      cronExp[3] = '*/14';
      break;
    case 'MONTHLY':
      break;
    case 'EVERY_OTHER_MONTH':
      cronExp[4] =
        (new Date().getMonth() + 1) % 2 === 0
          ? '2,4,6,8,10,12'
          : '1,3,5,7,9,11';
      break;
    case 'QUARTERLY':
      const m = new Date().getMonth() + 1;
      const s = [];
      for (let i = 0; i < 12; i += 3) {
        s.push((m + i) % 12 || 12);
      }
      cronExp[4] = s.join(',');
      break;
  }

  const weekdays = {
    MONDAY: '1',
    TUESDAY: '2',
    WEDNESDAY: '3',
    THURSDAY: '4',
    FRIDAY: '5',
  };
  if (cron === 'EVERY_DAY') {
    // use initial date *
  } else if (cron === 'LAST_DAY') {
    cronExp[3] = 'L';
  } else if (Reflect.get(weekdays, cron)) {
    // handle weekdays
    cronExp[5] = Reflect.get(weekdays, cron);
  } else {
    // handle date from 1 to 30
    cronExp[3] = cron;
  }

  const cronStr = cronExp.join(' ');
  return cronStr;
}

export const statusSelected = () => {
  setTimeout(() => {
    const el = document.getElementsByClassName('table-header-filter-panel')[0];
    el.closest('.cdk-overlay-pane')?.classList?.add('panelPosition');
  });
};

export const convertEndDate = (endDate) => {
  if (endDate) {
    return new Date(
      new Date(endDate).setHours(0, 0, 0, 0) + 24 * 60 * 60 * 1000 - 1,
    ).toISOString();
  } else {
    return undefined;
  }
};

export const daysBetween = (start: Date, end: Date): number => {
  const startDate = moment(start || new Date());
  const endDate = moment(end || new Date());
  return endDate.diff(startDate, 'days');
}

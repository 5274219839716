import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DropdownModule } from '@appkit4/angular-components/dropdown';
import { HeaderModule } from '@appkit4/angular-components/header';
import { TooltipModule } from '@appkit4/angular-components/tooltip';
import {
  AuthService,
  EmitService,
  LayoutService,
  PendoService,
  UserService,
} from '@core';
import { NotificationsService } from '@core/services/notifications.service';
import { TaskService } from '@core/services/task.service';
import { LayoutConfig, Tenant, User } from '@models';
import { AvatarHandleDirective } from '@shared/directive/avatar-handle/avatar-handle.directive';
import { plainToClass } from 'class-transformer';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DecodeStringPipe } from '@shared/pipe/decode-string/decode-string.pipe';
import {
  AvatarComponent,
  CustomizeTemplate,
} from '@shared/component/appkit4-avatar/avatar.component';
import { ToggleModule } from '@appkit4/angular-components/toggle';
import { StyleControlService } from '@core/services/style-control.service';

@Component({
  selector: 'app-bk-navbar',
  standalone: true,
  imports: [
    CommonModule,
    HeaderModule,
    TooltipModule,
    NgxPermissionsModule,
    AvatarHandleDirective,
    DecodeStringPipe,
    AvatarComponent,
    CustomizeTemplate,
    DropdownModule,
    ToggleModule,
  ],
  templateUrl: './bk-navbar.component.html',
  styleUrls: ['./bk-navbar.component.scss'],
})
export class BkNavbarComponent implements OnInit {
  layoutConfig: LayoutConfig;
  insideFrame = false;

  authUser$: Observable<User> = this.authService.user$;

  tenantByRoute: Tenant | any;
  backTitle: string;
  crumbTitle: string;

  showTenantName = false;
  subscription: Subscription;

  get pendoId(): string {
    return this.pendoService.generalPendoId('toolbar', 'ham-menu-icon');
  }

  get avatarPendoId(): string {
    return this.pendoService.generalPendoId('toolbar', 'user-profile-icon');
  }

  get accountPendoId(): string {
    return this.pendoService.generalPendoId(
      'toolbar',
      'user-profile-menu',
      'account-connections',
    );
  }

  get userManagementPendoId(): string {
    return this.pendoService.generalPendoId(
      'toolbar',
      'user-profile-menu',
      'user-management',
    );
  }

  navList: any = [
    {
      value: 'Info',
      category: 'info',
    },
    {
      category: 'divider',
    },
    {
      value: 'My_Profile',
      link: '/bookkeeper/profile',
      label: 'My Profile',
      category: 'link',
    },
    {
      value: 'User_Management_PWC',
      link: '/bookkeeper/member',
      label: 'User Management',
      permission: 'USER_MGMT_VIEW_USER_PWC',
      category: 'link',
    },
    {
      value: 'Notification_Preference',
      link: '/bookkeeper/email-notification',
      label: 'Notification Preferences',
      permission: 'VIEW_PROFILE_PWC',
      category: 'link',
    },
    {
      value: 'Permission',
      link: '/bookkeeper/permission',
      label: 'Permissions',
      permission: 'VIEW_PROFILE_PWC',
      category: 'link',
    },
    {
      value: 'Activity_Log_PwC',
      link: '/bookkeeper/activity-log',
      label: 'Activity Log',
      permission: 'USER_MGMT_VIEW_ACTIVITY_LOG_PWC',
      category: 'link',
    },
    {
      value: 'Activity_Log',
      link: '/activity-log',
      label: 'Activity Log',
      permission: 'CUSTOMER_VIEW_ACTIVITY_LOG',
      category: 'link',
    },
    {
      category: 'divider',
    },
    {
      value: 'change_theme',
      label: 'Light/Dark theme',
      descValue: 'T',
      category: 'change_theme',
    },
    {
      value: 'Logout',
      link: 'logout',
      label: 'Log Out',
      permission: '',
      category: 'link',
    },
  ];

  permissionsListObj = {} as any;

  routerConfigs = [
    {
      link: '/bookkeeper/customers/*/onboarding/task-list',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/customers/*/onboarding/task-detail',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/task-list/bk-task-list/*/all-task-list',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/task-list/bk-task-list/*/all-task-list/task-detail',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/task-list/bk-task-list/task-detail',
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/customers/*/reports',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/customers/*/member',
      showTenantName: true,
      setCrumbTitle: true,
    },
    {
      link: '/bookkeeper/customers/*/quick-links',
      showTenantName: true,
      setCrumbTitle: true,
      setBackTitle: true,
    },
    {
      link: '/bookkeeper/customers/*/email-sent/detail',
      showTenantName: true,
      setCrumbTitle: true,
      setBackTitle: true,
    },
    {
      link: '/bookkeeper/member/*/reassign-special-roles',
      setBackTitle: true,
    },
    {
      link: '/bookkeeper/member/*/assign-customers',
      setBackTitle: true,
    },
    {
      link: '/bookkeeper/customers/customer-form/*/customer-detail',
      showTenantName: true,
      setCrumbTitle: true,
    },
  ];

  constructor(
    public layoutService: LayoutService,
    private authService: AuthService,
    private readonly pendoService: PendoService,
    public readonly emitService: EmitService,
    private readonly taskService: TaskService,
    private readonly userService: UserService,
    public readonly notificationsService: NotificationsService,
    private readonly permissionsService: NgxPermissionsService,
    private readonly router: Router,
    private readonly styleControlService: StyleControlService,
  ) {
    this.layoutConfig = layoutService.layoutConfig;
    this.initNavList();
  }

  ngOnInit() {
    this.permissionsListObj = this.permissionsService.getPermissions();
    const url = this.router.url;
    this.checkNavItem(url);
    this.getDataByRoute(url);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const currentUrl = (event as NavigationEnd).url;
        this.getDataByRoute(currentUrl);
        this.checkNavItem(currentUrl);
      });
  }

  checkNavItem(url: string) {
    this.navList.forEach((n) => (n.checked = false));
    const navItem = this.navList.find((n) => url.includes(n.link));
    if (navItem) {
      navItem.checked = true;
    }
  }

  getDataByRoute(url: string) {
    const { tenantKey, userKey } = this.layoutConfig;
    if (tenantKey) {
      this.getTenantInfo(tenantKey, url);
    } else if (userKey) {
      this.getUserInfo(userKey, url);
    } else {
      this.matchRouteConfig(url);
    }
  }

  getTenantInfo(tenantKey: number, url: string) {
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.taskService
      .getTenantInfo(tenantKey)
      .subscribe((res) => {
        this.tenantByRoute = res;
        this.backTitle = this.tenantByRoute?.nme;
        this.crumbTitle = this.tenantByRoute?.nme;
        this.layoutService.tenantByRoute$.next(res);
        this.matchRouteConfig(url);
      });
  }

  getUserInfo(userKey: number, url: string) {
    this.subscription && this.subscription.unsubscribe();
    this.subscription = this.userService
      .getUserInfo(userKey)
      .subscribe((res) => {
        const userInfo = plainToClass(User, res);
        this.backTitle = userInfo?.name || userInfo.email;
        this.layoutService.userByRoute$.next(userInfo);
        this.matchRouteConfig(url);
      });
  }

  matchRouteConfig(url: string) {
    this.showTenantName = false;
    url = url.split('?')[0];
    url = url.split('#')[0];
    const urlParts = url.split('/');
    for (const cfg of this.routerConfigs) {
      const { link, setCrumbTitle, setBackTitle, showTenantName } = cfg;
      const linkParts = link.split('/');
      if (linkParts.length !== urlParts.length) {
        continue;
      }

      let isMatch = true;
      for (let i = 0; i < linkParts.length; i++) {
        if (linkParts[i] !== '*' && linkParts[i] !== urlParts[i]) {
          isMatch = false;
          break;
        }
      }
      if (isMatch) {
        this.showTenantName = showTenantName;
        if (setCrumbTitle) {
          this.layoutService.setBackInfo({
            crumbTitle: this.crumbTitle,
          });
        }
        if (setBackTitle) {
          this.layoutService.setBackInfo({
            title: this.backTitle,
          });
        }
        break;
      }
    }
  }

  initNavList() {
    setTimeout(() => {
      this.navList = this.navList.filter((item) => {
        return !item.permission || this.permissionsListObj[item.permission];
      });
    });
  }

  async logout() {
    // this.snav.close();
    await this.authService.logout();
  }

  onSelectItem({ selected }: any) {
    if (selected.value === 'Logout') {
      this.logout();
    } else if (selected.value === 'change_theme') {
      this.themeChange();
    } else {
      this.router.navigate([selected.link]);
    }
  }

  toggleNotificationPanel() {
    this.emitService.showNotificationsPanel$.next(
      !this.emitService.showNotificationsPanel$.getValue(),
    );
  }

  navigateToMainPage() {
    this.router.navigate(['/']);
  }

  themeChange(): void {
    if (this.styleControlService.dataMode === 'dark') {
      this.styleControlService.dataMode = 'light';
      this.styleControlService.updateTheme('LIGHT').subscribe();
    } else {
      this.styleControlService.dataMode = 'dark';
      this.styleControlService.updateTheme('DARK').subscribe();
    }
  }
}

import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-non-login-container',
  standalone: true,
  imports: [CommonModule, RouterOutlet, FooterComponent],
  templateUrl: './non-login-container.component.html',
  styleUrls: ['./non-login-container.component.scss'],
})
export class NonLoginContainerComponent {}

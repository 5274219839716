import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { BreadcrumbCusComponent } from '../breadcrumb/breadcrumbCus.component';
import { ButtonModule } from '@appkit4/angular-components/button';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EmitService, LayoutService } from '@core';
import { AsyncPipe } from '@angular/common';
import { DecodeStringPipe } from '@shared/pipe/decode-string/decode-string.pipe';
import { unescape } from 'lodash';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.scss'],
  standalone: true,
  imports: [
    RouterLink,
    BreadcrumbCusComponent,
    ButtonModule,
    AsyncPipe,
    DecodeStringPipe,
  ],
})
export class BackComponent implements OnDestroy {
  private subs: Subscription[] = [];

  backText = '';
  backInfos = [
    {
      routerLink: '/bookkeeper/customers/customer-form',
      title: 'Add New Customer',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: 'Add New Customer' },
      ],
    },
    {
      routerLink: '/bookkeeper/email-templates/detail',
      title: 'Add Template',
      crumbList: [
        {
          title: 'Mail Templates',
          icon: 'icon-email-outline',
          path: '/bookkeeper/email-templates',
        },
        { title: 'Add Template' },
      ],
    },
    {
      routerLink: '/bookkeeper/email-templates/*/detail',
      title: 'Edit Template',
      crumbList: [
        {
          title: 'Mail Templates',
          icon: 'icon-email-outline',
          path: '/bookkeeper/email-templates',
        },
        { title: 'Edit Template' },
      ],
    },
    {
      routerLink: '/bookkeeper/mail-box/*/detail',
      title: '',
      crumbList: [
        {
          title: 'Inbox',
          icon: 'icon-email-outline',
          path: '/bookkeeper/mail-box',
        },
        { title: '' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/email-sent/detail',
      title: '',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Send Email' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/quick-links',
      title: '',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Quick Links' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/customer-form/*/sub-entity',
      title: 'Add New Subentity',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: 'Add New Subentity' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/customer-form/*/customer-detail',
      title: 'Customers',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Customer details' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/onboarding/task-detail',
      title: '',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        {
          title: 'Tasks',
          path: '/bookkeeper/customers/*/onboarding/task-list',
        },
        { title: '' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/onboarding/task-list',
      title: 'Tasks',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Tasks' },
      ],
    },
    {
      routerLink: '/bookkeeper/customer-groups/*/detail',
      title: '',
      crumbList: [
        {
          title: 'Customer Groups',
          icon: 'icon-community-outline',
          path: '/bookkeeper/customer-groups',
        },
        { title: 'Customers Groups details' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/reports',
      title: 'Files',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Files' },
      ],
    },
    {
      routerLink: '/bookkeeper/customers/*/member',
      title: 'Manage Customer Users',
      crumbList: [
        {
          title: 'Customers',
          icon: 'icon-community-outline',
          path: '/',
        },
        { title: '' },
        { title: 'Manage Customer Users' },
      ],
    },
    {
      routerLink: '/bookkeeper/template-library/*/task-list',
      title: '',
      crumbList: [
        {
          title: 'Task Templates',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/template-library',
        },
        { title: 'Template details' },
      ],
    },
    {
      routerLink: '/bookkeeper/template-library/*/task-detail',
      title: '',
      crumbList: [
        {
          title: 'Task Templates',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/template-library',
        },
        {
          title: 'Template details',
          path: '/bookkeeper/template-library/*/task-list',
        },
        { title: '' },
      ],
    },
    {
      routerLink: '/bookkeeper/task-library/task-detail',
      title: '',
      crumbList: [
        {
          title: 'Task Library',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/task-library',
        },
        { title: '' },
      ],
    },
    {
      routerLink: '/bookkeeper/task-list/bk-task-list/*/all-task-list',
      title: 'Tasks',
      crumbList: [
        {
          title: 'My Task',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/task-list/bk-task-list',
        },
        { title: '' },
        { title: 'Tasks' },
      ],
    },
    {
      routerLink:
        '/bookkeeper/task-list/bk-task-list/*/all-task-list/task-detail',
      title: '',
      crumbList: [
        {
          title: 'My Task',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/task-list/bk-task-list',
        },
        {
          title: '',
          path: '/bookkeeper/task-list/bk-task-list/*/all-task-list',
        },
        { title: '' },
      ],
    },
    {
      routerLink: '/bookkeeper/task-list/bk-task-list/task-detail',
      title: 'Task details',
      crumbList: [
        {
          title: 'My Task',
          icon: 'icon-check-hook-outline',
          path: '/bookkeeper/task-list/bk-task-list',
        },
        { title: 'Task details' },
      ],
    },
    {
      routerLink: '/bookkeeper/member/*/reassign-special-roles',
      title: '',
      crumbList: [
        {
          title: 'User Management',
          icon: 'icon-community-outline',
          path: '/bookkeeper/member',
        },
        { title: 'Reassign Team Lead/Reviewer' },
      ],
    },
    {
      routerLink: '/bookkeeper/member/*/assign-customers',
      title: '',
      crumbList: [
        {
          title: 'User Management',
          icon: 'icon-community-outline',
          path: '/bookkeeper/member',
        },
        { title: 'Assigned Customers' },
      ],
    },
    // customer portal (from Sidenav)
    {
      routerLink: '/customer/task-list/customers-task-list/task-detail',
      title: 'Tasks',
      crumbList: [
        {
          title: 'Tasks',
          icon: 'icon-check-hook-outline',
          path: '/customer/task-list/customers-task-list',
        },
        { title: 'Task details' },
      ],
    },
    // bookkeeper portal (from Sidenav)
    {
      routerLink: '/customer/*/task-list/customers-task-list/task-detail',
      title: 'Tasks',
      crumbList: [
        {
          title: 'Tasks',
          icon: 'icon-check-hook-outline',
          path: '/customer/*/task-list/customers-task-list',
        },
        { title: 'Task details' },
      ],
    },
  ];
  crumbList = this.backInfos[0].crumbList;
  routeKeys = [];

  constructor(
    private readonly router: Router,
    private readonly emitService: EmitService,
    private readonly layoutService: LayoutService,
  ) {
    this.matchBackInfo(this.router.url);
    this.subs.push(
      this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe((event) => {
          this.matchBackInfo((event as NavigationEnd).url);
        }),
      this.emitService.goBackEvent.subscribe(() => {
        this.goBack();
      }),
      this.layoutService.backInfo$.subscribe(({ title, crumbTitle }) => {
        if (title) {
          this.backText = unescape(title);
          const lastIndex = this.crumbList.length - 1;
          if (!this.crumbList[lastIndex].title) {
            this.crumbList[lastIndex].title = title;
          }
        }

        if (crumbTitle && this.crumbList[1]?.title === '') {
          this.crumbList[1].title = unescape(crumbTitle);
        }
      }),
    );
  }

  matchBackInfo(url: string) {
    url = url.split('?')[0];
    url = url.split('#')[0];
    const urlParts = url.split('/');
    for (const info of this.backInfos) {
      const { crumbList, routerLink, title } = info;
      const linkParts = routerLink.split('/');
      if (linkParts.length !== urlParts.length) {
        continue;
      }

      let isMatch = true;
      for (let i = 0; i < linkParts.length; i++) {
        if (linkParts[i] !== '*' && linkParts[i] !== urlParts[i]) {
          isMatch = false;
          this.routeKeys = [];
          break;
        } else if (linkParts[i] === '*') {
          this.routeKeys.push(urlParts[i]);
        }
      }
      if (isMatch) {
        this.crumbList = crumbList.map((item) => {
          // to keep the original crumbList immutable, do not use item of crumbList directly
          const crumb = { ...item };
          let path = crumb.path;
          if (path && this.routeKeys.length > 0) {
            const routeKeys = this.routeKeys.slice();
            while (path.includes('*')) {
              path = path.replace(/\*/, routeKeys.shift());
            }
            crumb.path = path;
          }
          return crumb;
        });
        this.backText = title;
        break;
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
  }

  goBack() {
    const lastIndex = this.crumbList.length - 1;
    let prev = 1;
    let routerLink;
    while (!routerLink) {
      routerLink = this.crumbList[lastIndex - prev].path;
      prev++;
    }
    while (routerLink.includes('*')) {
      routerLink = routerLink.replace(/\*/, this.routeKeys.shift());
    }
    this.router.navigate([routerLink]);
  }
}

<app-spinner *ngIf="emitService.loadingAPICount > 0"></app-spinner>
<div class="page-wrapper">
  <div class="header">
    <app-cus-navbar></app-cus-navbar>
  </div>
  <div [ngStyle]="{'--sidebar-width-open':(layoutService?.fullScreen$ | async) ? '72px': '280px'}"
    [class.has-data-table]="layoutConfig?.fullScreen" class="layout-container layout-normal-container">
    <div class="sidenav-container">
      <aside class="navigation-wrapper">
        <app-cus-sidenav></app-cus-sidenav>
      </aside>
    </div>
    <div class="content-wrapper">
      <div class="content-window flex-direction-column">
        <div class="all-print" id="maincontent" tabindex="-1" role="main"
          [ngClass]="{'content-wrapper-overflow': emitService.showNotificationsPanel$ | async, 'ap-pt-spacing-7': !layoutConfig.hasBack}">
          <app-back *ngIf="layoutConfig.hasBack"></app-back>
          <router-outlet></router-outlet>
        </div>
        <div class="footer ap-grid flex-normal">
          <app-footer class="g-col-12"></app-footer>
        </div>
      </div>
    </div>
  </div>
</div>
<app-notifications></app-notifications>
<app-panel-sidenav></app-panel-sidenav>
<app-encapsulated-banner></app-encapsulated-banner>

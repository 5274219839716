import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { COMMENT_TYPE, EmitService, LayoutService, UtilService } from '@core';
import { PanelInfo } from '@core/gql/comments-notes.gql';
import { CommentsNotesService } from '@core/services/comments-notes.service';
import { Subscription, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { unescape } from 'lodash';
import { plainToClass } from 'class-transformer';
import { User } from '@models';

@Component({
  template: '',
})
export class CommentsNotesBaseComponent
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  COMMENT_TYPE = COMMENT_TYPE;
  @Input() panelInfo: PanelInfo;
  @Output() sendCommentsEvent: EventEmitter<void> = new EventEmitter();
  @Output()
  closeSidePanel: EventEmitter<void> = new EventEmitter();
  @Input() memberInfo: Array<any> = [];
  mentionTimmer: any;
  tags = [];
  tenantKey: number;
  isInitFinished = false;
  subs: Subscription[] = [];
  commentList: any[] = [];
  taskName = '';
  comments: string;
  mentionsConfig = {
    items: [],
    triggerChar: '@',
    labelKey: 'nme',
    dropUp: true,
    mentionFilter: this.filter,
    mentionSelect: (item: any) => `@${item.firstNme + ' ' + item.lastNme}`,
  };
  DELIMITER = '#&';
  MAX_FILE_SIZE = 2 * 1024 * 1024;
  isOverSize = false;
  selectedFile: File;
  isFailedToSend = false;
  attachmentFileName = '';
  attachmentTailName = '';
  fileInput: ElementRef;
  commentsContainerElement: ElementRef;
  panelTypeNme = 'Tasks';
  commentHeight = 0;
  tempElement;
  textEditorConfig = {
    mention: {
      dropdownLimit: 1000,
      feeds: [
        {
          marker: '@',
          feed: (searchUser: string) => this.getFeedItems(searchUser),
          itemRenderer: (item: any) => this.customItemRenderer(item),
        },
      ],
    },
  };
  mentionedUserList = [];
  constructor(
    protected commentsNotesService: CommentsNotesService,
    protected readonly layoutService: LayoutService,
    protected readonly emitService: EmitService,
    protected readonly utilService: UtilService,
  ) {}
  ngOnInit(): void {
    this.tenantKey = this.layoutService.layoutConfig.tenantKey;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.panelInfo.currentValue) {
      this.listComments();
      this.listUser();
      if (this.panelInfo.panelType === COMMENT_TYPE.TASK_COMMENT) {
        this.panelTypeNme = 'Tasks';
      } else if (this.panelInfo.panelType === COMMENT_TYPE.FILE_COMMENT) {
        this.panelTypeNme = 'Files';
      }
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub.unsubscribe());
    if (this.tempElement) (this.tempElement as HTMLInputElement).focus();
  }
  ngAfterViewInit(): void {
    this.tempElement = document.activeElement;
    setTimeout(() => {
      const textElement = this.getAllFocusableElementsOf(
        document.getElementById('commentsTextarea'),
      );
      (textElement[0] as HTMLInputElement).focus();
    });
    this.listComments();
    this.getUsers().subscribe((next) => {
      setTimeout(() => {
        this.memberInfo = this.mentionsConfig.items;
        this.memberInfo.forEach((item) => (item.id = '@' + item.nme));
      });
    });
  }
  listComments() {
    this.emitService.startLoading();
    const { panelType, taskInfo } = this.panelInfo;
    const { contentKey, unreadCount } = taskInfo || {};
    this.subs.push(
      this.commentsNotesService
        .listComments({
          commentType: panelType,
          contentKey,
        })
        .pipe(
          map((res) => {
            this.taskName = '';
            return res.data.map((item) => {
              const regex = new RegExp(
                `(\\@[\\w|\'| ]+)${this.DELIMITER}`,
                'gi',
              );
              const content = unescape(item?.content)?.replace(
                regex,
                `<span class="mention">$1</span>`,
              );
              return {
                ...item,
                content,
                user: plainToClass(User, item?.user),
              };
            });
          }),
        )
        .subscribe((res) => {
          this.emitService.endLoading();
          this.commentList = res;
        }),
    );

    if (unreadCount) {
      this.subs.push(
        this.commentsNotesService
          .markNotification({ commentType: panelType, contentKey })
          .subscribe(),
      );
    }
  }

  getUsers(): Observable<any> {
    const { panelType, tenantKey, taskInfo } = this.panelInfo;
    const { FILE_COMMENT, FILE_NOTE } = COMMENT_TYPE;
    if ([FILE_COMMENT, FILE_NOTE].includes(panelType)) {
      return this.commentsNotesService.getUsersForComment(
        tenantKey,
        taskInfo?.contentKey,
      );
    } else {
      return this.commentsNotesService.getUsersInTaskList(tenantKey);
    }
  }

  listUser() {
    while (this.mentionsConfig.items.length > 0) {
      this.mentionsConfig.items.pop();
    }
    this.subs.push(
      this.getUsers()
        .pipe(
          map((res) => {
            const users = [...res.data.bks];
            const { panelType, taskInfo } = this.panelInfo;
            const { TASK_COMMENT, FILE_COMMENT } = COMMENT_TYPE;
            const isCommentPanel = [TASK_COMMENT, FILE_COMMENT].includes(
              panelType,
            );
            if (
              (isCommentPanel && taskInfo?.visible) ||
              taskInfo?.isCustomerComment
            ) {
              users.push(...res.data?.customers);
            }
            return users
              .map((user) => {
                return {
                  ...user,
                  firstNme: unescape(user.firstNme),
                  lastNme: unescape(user.lastNme),
                  nme: unescape(user?.firstNme) + ' ' + unescape(user?.lastNme),
                };
              })
              .sort((a, b) => {
                if (a?.nme?.toLowerCase() < b?.nme?.toLowerCase()) {
                  return -1;
                } else {
                  return 1;
                }
              });
          }),
        )
        .subscribe((userList) => {
          this.mentionsConfig.items.push(...userList);
          this.isInitFinished = true;
        }),
    );
  }

  itemSelected(item: any) {
    setTimeout(() => {
      this.addTags(item);
    });
  }

  filter(searchString: string, items: any[]): any[] {
    return items.filter((item) => {
      if (
        unescape(item.firstNme.toLowerCase()).includes(
          unescape(searchString.toLowerCase()),
        ) ||
        unescape(item.lastNme.toLowerCase()).includes(
          unescape(searchString.toLowerCase()),
        )
      ) {
        return true;
      } else {
        return false;
      }
    });
  }

  selectFile($event) {
    if ($event.target.files[0].size > this.MAX_FILE_SIZE) {
      this.removeFile();
      this.isOverSize = true;
      this.isFailedToSend = false;
      return;
    }
    this.isOverSize = false;
    this.selectedFile = $event.target.files[0];
    this.attachmentFileName = this.selectedFile.name;
    setTimeout(() => {
      const sp = document.getElementsByClassName(
        'selected-file-name',
      )[0] as HTMLElement;
      if (sp.offsetWidth >= 322) {
        this.attachmentTailName = this.attachmentFileName.substring(
          this.attachmentFileName.length - 8,
        );
      } else {
        this.attachmentTailName = '';
      }
    });
  }

  removeFile() {
    this.selectedFile = null;
    this.fileInput.nativeElement.value = '';
    const sp = document.getElementsByClassName(
      'selected-file-name',
    )[0] as HTMLElement;
    sp?.removeAttribute('title');
  }

  downloadAttachment({ commentKey, attachedFileName }) {
    this.emitService.startLoading();
    this.subs.push(
      this.commentsNotesService
        .getCommentAttachment(commentKey)
        .subscribe(({ data }) => {
          this.emitService.endLoading();
          if (data) {
            const { base64Content } = data;
            this.utilService.downloadAttachmentFile(
              base64Content,
              attachedFileName,
            );
          }
        }),
    );
  }

  closePanel() {
    this.isFailedToSend = false;
    this.isOverSize = false;
    this.clearContent();
    this.closeSidePanel.emit();
  }
  modifyString(text) {
    // Use a global regular expression to find all closing </span> tags
    const spanCloseTagPattern = /<\/span>/g;
    // Replace each closing </span> tag with </span>#&
    return text.replace(spanCloseTagPattern, `</span>${this.DELIMITER}`);
  }

  sendComments() {
    this.emitService.startLoading();
    const content = this.resolveContent();
    const commentInput = {
      content,
      mentionedUserKeys: this.mentionedUserList.map((i) => i?.userKey),
      attachment: this.selectedFile,
      commentType: this.panelInfo.panelType,
      contentKey: this.panelInfo?.taskInfo?.contentKey,
    };

    this.subs.push(
      this.commentsNotesService
        .saveComment(commentInput)
        .subscribe(({ status }) => {
          this.emitService.endLoading();
          if (status === 'SUCCESS') {
            this.clearContent();
            this.listComments();
            this.sendCommentsEvent.emit();
            this.isFailedToSend = false;
            this.isOverSize = false;
          } else {
            this.isFailedToSend = true;
          }
        }),
    );
  }

  clearContent() {
    this.comments = '';
    this.tags = [];
    this.removeFile();
  }

  resolveContent(): string {
    let content = '';
    this.tags
      .sort((a, b) => a.indices.start - b.indices.start)
      .forEach((tag, index) => {
        if (index > 0) {
          content =
            content +
            this.comments.substring(
              this.tags[index - 1].indices.end,
              tag.indices.start,
            ) +
            tag.data +
            this.DELIMITER;
        } else {
          content =
            this.comments.substring(0, tag.indices.start) +
            tag.data +
            this.DELIMITER;
        }
      });
    if (this.tags.length > 0) {
      content =
        content +
        this.comments.substring(this.tags[this.tags.length - 1].indices.end);
    } else {
      content = this.comments;
    }
    return this.removeHtmlTags(this.modifyString(content));
  }

  scrollToBottom(): void {
    try {
      this.commentsContainerElement.nativeElement.scrollTop =
        this.commentsContainerElement.nativeElement.scrollHeight;
    } catch (err) {}
  }

  addTags(item) {
    const tmpTags = [...this.tags];
    if (this.tags.findIndex((tag) => tag.data === '@' + item.nme) < 0) {
      const start = this.comments.indexOf('@' + item.nme);
      tmpTags
        .filter((tag) => tag.indices.start >= start)
        // .sort((a, b) => a.indices.start - b.indices.start)
        .forEach((tag) => {
          tag.indices.start = tag.indices.start + item.nme.length + 1;
          tag.indices.end = tag.indices.end + item.nme.length + 1;
        });
      tmpTags.push({
        indices: {
          start,
          end: start + ('@' + item.nme).length,
        },
        cssClass: 'highlight-color',
        data: '@' + item.nme,
        item,
      });
      this.tags = tmpTags;
    } else {
      this.adjustHighlightPosition();
    }
  }

  adjustHighlightPosition() {
    setTimeout(() => {
      this.tags = this.tags.filter((tag) => {
        const regx = new RegExp(tag.data);
        return regx.test(this.comments);
      });
      const tmpTags = [...this.tags];
      this.tags = [];
      tmpTags.forEach((tag) => {
        const allIndex = this.findAllIndex(this.comments, tag.data);
        allIndex.forEach((index) => {
          if (this.tags.findIndex((_tag) => _tag.indices.start === index) < 0) {
            this.tags.push({
              indices: {
                start: index,
                end: index + tag.data.length,
              },
              cssClass: 'highlight-color',
              data: tag.data,
              item: tag.item,
            });
          }
        });
      });
    });
  }

  findAllIndex(allString: string, searchString: string) {
    const indices = [];
    let index = allString.indexOf(searchString, 0);
    while (index >= 0) {
      indices.push(index);
      index = allString.indexOf(searchString, index + 1);
    }
    return indices;
  }

  getAllFocusableElementsOf = (el: HTMLElement) => {
    return Array.from<HTMLElement>(
      el.querySelectorAll(
        'button, [href], input, select, textarea, [role="textbox"], [tabindex]:not([tabindex="-1"])',
      ),
    );
  };

  extractTextBetweenPTags(input: string): string | null {
    const regex = /<p>(.*?)<\/p>/;
    const match = input.match(regex);
    return match ? match[1] : null;
  }

  getFeedItems(queryText) {
    if (this.mentionTimmer) {
      clearTimeout(this.mentionTimmer);
    }
    return new Promise((resolve) => {
      this.mentionTimmer = setTimeout(() => {
        const itemsToDisplay = this.memberInfo.filter((item) => {
          const str = queryText.toLowerCase();
          return (
            item.nme.toLowerCase().includes(str) ||
            item.email.toLowerCase().includes(str)
          );
        });
        resolve(itemsToDisplay);
      }, 100);
    });
  }

  customItemRenderer(item) {
    const divEle = document.createElement('div');
    divEle.classList.add('edit-list-container');
    divEle.insertAdjacentHTML(
      'afterbegin',
      `
      <div class="list-content">
          <div class="avatar-name-box">
            <span class="name-text">${item.firstNme?.charAt(
              0,
            )}${item.lastNme?.charAt(0)}</span>
          </div>
          <div class="userinfo-box">
            <span class="userinfo-full-name" title="${item.nme}">${
        item.nme
      }</span>
            <span class="userinfo-email" title="${item.email}">${
        item.email
      }</span>
          </div>
      </div>
    `,
    );
    return divEle;
  }

  onWriteChange(event) {
    const text = this.extractTextBetweenPTags(event.data);
    this.comments = text;
    const editor = event.editor;
    // create a range spanning the whole document
    const range = editor.model.createRangeIn(editor.model.document.getRoot());
    const mentions = [];
    //get all mentioned user object
    for (const treeWalker of range.getWalker({ ignoreElementEnd: true })) {
      if (treeWalker.type === 'text') {
        const node = treeWalker.item.textNode;
        if (node.hasAttribute('mention')) {
          const mention = node.getAttribute('mention');
          if (mention) {
            mentions.push(mention);
          }
        }
      }
    }
    this.mentionedUserList = mentions;
  }

  removeHtmlTags(text) {
    // Create a new DOM element
    let tempDiv = document.createElement('div');
    // Set the innerHTML of the element to the input text
    tempDiv.innerHTML = text;
    // Use the textContent property to get the text without HTML tags
    return tempDiv.textContent || tempDiv.innerText || '';
  }
}
